import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import socketIOClient from "socket.io-client";
import ExchangeNavBar from "../widgets/ExchangeNavBar";
import { useNavigate, useParams } from "react-router-dom";
import { imageUrl, socketUrl } from "../constant/BaseUrl";
import DocumentMeta from "react-document-meta";
import { ProductName } from "../constant/ProductName";
import TradingViewForexWidgit from "../partials/TradingViewForexWidgit";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Decimal from "decimal.js";
import toast from "react-hot-toast";
import update from "immutability-helper";
import {
  useCancleOrderFxMutation,
  useClosePositionMutation,
  useCreateOrderClickMutation,
  useGetSymbolInfoMutation,
  useModifyOrderClickMutation,
} from "../../redux/forexApi";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import BigNumber from "bignumber.js";
import { useGetallUserQuery } from "../../redux/userApi";
import DraggableItem from "./DraggableItem";
import pako from "pako";
import { Buffer } from "buffer";
import "../../App.css";
import { useTranslation } from "react-i18next";
import { formatDecimal, formatTakeProfit } from "../../util/decimalFormatter";
const ResponsiveGridLayout = WidthProvider(Responsive);

const layouts = {
  lg: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 1.4, static: true },
    {
      i: "pairslist",
      x: 9,
      y: 0,
      w: 3,
      h: 12,
      minW: 3,
      minH: 4,
    },
    { i: "buy", x: 9, y: 1, w: 3, h: 11, minW: 3, minH: 8 },
    { i: "chart", x: 0, y: 1, w: 9, h: 12, minW: 4, minH: 6 },
    { i: "marketdepth", x: 9, y: 2, w: 3, h: 8.5, minW: 2, minH: 8 },
    { i: "orderdetails", x: 0, y: 9, w: 9, h: 19.5, minW: 3 },
  ],
  md: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 1.3, static: true },
    { i: "pairslist", x: 7, y: 1, w: 3, h: 12 },
    { i: "chart", x: 0, y: 1, w: 7, h: 12 },
    { i: "buy", x: 7, y: 2, w: 3, h: 12, minW: 3, minH: 15, setIndex: 6 },
    { i: "marketdepth", x: 7, y: 3, w: 3, h: 8 },
    { i: "orderdetails", x: 0, y: 2, w: 7, h: 20 },
  ],
  sm: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 1.4, static: true },
    { i: "pairslist", x: 4, y: 0, w: 2, h: 9 },
    { i: "chart", x: 0, y: 0, w: 4, h: 9 },
    { i: "buy", x: 4, y: 1, w: 2, h: 10, minW: 5, minH: 8, setIndex: 6 },
    { i: "marketdepth", x: 4, y: 13, w: 2, h: 8 },
    { i: "orderdetails", x: 0, y: 1, w: 4, h: 18 },
  ],
  xs: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 4.8, static: true },
    { i: "pairslist", x: 0, y: 1, w: 12, h: 6 },
    { i: "chart", x: 0, y: 7, w: 12, h: 9 },
    { i: "buy", x: 0, y: 1, w: 4, h: 12, minW: 5, minH: 8, setIndex: 6 },
    { i: "marketdepth", x: 0, y: 13, w: 12, h: 9 },
    { i: "orderdetails", x: 0, y: 18, w: 12, h: 9 },
  ],
};

const getFromLocalStorage = (key) => {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key);
    return saved ? JSON.parse(saved) : null;
  }
  return null;
};

const saveToLocalStorage = (key, value) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const defaultError = {
  price: "",
  stopPrice: "",
  takeProfit: "",
};

export const ContextMenu = ({ x, y, onAction, menuRef }) => {
  return (
    <div ref={menuRef} className="context-menu bg2" style={{ top: y, left: x }}>
      <button onClick={() => onAction("close")}>
        <span>Close</span> <i className="bi bi-x-circle"></i>
      </button>
      <button onClick={() => onAction("modify")}>
        <span>Modify</span> <i className="bi bi-pencil-square"></i>
      </button>
      {/* <button onClick={() => onAction("Action 2")}>Modify</button> */}
    </div>
  );
};

const ForexExchange = (props) => {
  const localSocket = useRef(null);
  const { t } = useTranslation();
  const { symbol } = useParams();
  const { data } = useGetallUserQuery();
  const navigate = useNavigate();
  const [createOrderOneClick, { isLoading }] = useCreateOrderClickMutation();
  const [modifyOrderClick] = useModifyOrderClickMutation();
  const [getSymbolInfo] = useGetSymbolInfoMutation();
  const [closeOrderFx, { isLoading: positionClosing }] =
    useClosePositionMutation();
  const [cancleOrderFx] = useCancleOrderFxMutation();
  const [coinPairData, setCoinPairData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [isOrderModelOpen, setIsOrderModelOpen] = useState(false);
  const [selectedPair, setSelectedPair] = useState(null);
  const [assetsQuantity, setAssetsQuantity] = useState(0.01);
  const [sideAcvive, setSideAcvive] = useState("buy");
  const [theme, setTheme] = useState("dark");
  const [activeOrderTab, setActiveOrderTab] = useState("positions");
  const [openOrders, setOpenOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [closedOrders, setClosedOrders] = useState([]);
  const [cancleOrders, setCancleOrders] = useState([]);
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(0.01);
  const [allOrdersData, setAllOrdersData] = useState([]);
  const [selectedAsk, setSelectedAsk] = useState("");
  const [selectedBid, setSelectedBid] = useState("");
  const [stopPrice, setStopPrice] = useState("");
  const [isStopLoss, setIsStopLoss] = useState(false);
  const [userBalance, setUserBalance] = useState("0.00");
  const [margin, setMargin] = useState("0.00");
  const [freeMargin, setFreeMargin] = useState("0.00");
  const [marginLevel, setMarginLevel] = useState("0.00");
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [floatingProfits, setFloatingProfits] = useState(new BigNumber(0));
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [takeProfit, setTakeProfit] = useState("");
  const [isTakeProfit, setIsTakeProfit] = useState(false);
  const [orderMethod, setOrderMethod] = useState("market");
  const [refresh, setRefresh] = useState(false);
  const username = data?.user_name || "N/A";
  const [layout, setLayout] = useState(
    getFromLocalStorage("layouts") || layouts
  );
  const [removedItems, setRemovedItems] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [marketDepth, setMarketDepth] = useState([
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85915,
      MDEntrySize: 500000,
      QuoteEntryID: "0",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85914,
      MDEntrySize: 1600000,
      QuoteEntryID: "1",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85913,
      MDEntrySize: 4100000,
      QuoteEntryID: "2",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85912,
      MDEntrySize: 3000000,
      QuoteEntryID: "3",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85911,
      MDEntrySize: 3500000,
      QuoteEntryID: "4",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.8593,
      MDEntrySize: 500000,
      QuoteEntryID: "0",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85932,
      MDEntrySize: 4600000,
      QuoteEntryID: "1",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85933,
      MDEntrySize: 16000000,
      QuoteEntryID: "2",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85934,
      MDEntrySize: 10500000,
      QuoteEntryID: "3",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85935,
      MDEntrySize: 5000000,
      QuoteEntryID: "4",
    },
  ]);
  const [currentRightClickData, setCurrentRightClickData] = useState(null);
  const menuRef = useRef(null);
  const [monetary, setMonetary] = useState(new BigNumber(0));
  const [errors, setErrors] = useState(defaultError);
  const [show, setShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [currentOrderClosing, setCurrentClosingOrder] = useState(null);
  const [floatingProfitsClose, setFloatingProfitsClose] = useState(
    new BigNumber(0)
  );

  const handleDoubleClick = (rowData) => {
    if (positionClosing) return;
    setIsStopLoss(true);
    setIsTakeProfit(true);
    setSideAcvive(rowData.order_side.toLowerCase());
    setPrice(rowData.price);
    setStopPrice(rowData.stop_price);
    setTakeProfit(rowData.take_profit);
    setSelectedRowData(rowData);
    setShow(true);
    handleSocketMessages(localSocket, rowData.symbol);
  };

  const handleClose = () => {
    setShow(false);
    // setPrice("");
    // setStopPrice("");
    setTakeProfit("");
    setSelectedRowData(null);
    handleSocketMessages(localSocket, symbol);
  };

  const result = useMemo(() => {
    const qutiy = new BigNumber(quantity ? quantity : 0);
    return qutiy.multipliedBy(100000);
  }, [quantity]);

  useEffect(() => {
    setMonetary(result);
  }, [result]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLayoutChange = (layout, allLayouts) => {
    setLayout(allLayouts);
    saveToLocalStorage("layouts", allLayouts);
  };

  useEffect(() => {
    const savedLayouts = getFromLocalStorage("layouts");
    if (savedLayouts) {
      setLayout(savedLayouts);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const { data, status } = await getSymbolInfo({ symbol });
      if (data.status) {
        setSelectedBid(data.data.best_bid);
        setSelectedAsk(data.data.best_ask);
        setPrice(data.data.best_bid);
      }
    };
    fetch();
  }, [symbol]);

  useEffect(() => {
    if (removedItems.length > 0) {
      saveToLocalStorage("frl", removedItems);
    }
  }, [removedItems]);

  useEffect(() => {
    const removeItemsLocal = getFromLocalStorage("frl");
    if (removeItemsLocal) {
      setRemovedItems(removeItemsLocal);
    }
  }, []);

  useEffect(() => {
    if (!localSocket.current) {
      const mainState = {
        symbolType: "forex",
      };
      localSocket.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("jwtToken"),
        },
      });
      localSocket.current.on("connect", () => {
        console.log("Socket is connected");
        localSocket.current.emit("ping");
        localSocket.current.emit("getForexSymbol");
        localSocket.current.emit("userPendingOrderFX", mainState);
        localSocket.current.emit("userCloseOrderFX", mainState);
        localSocket.current.emit("userOpenOrderFX", mainState);
        localSocket.current.emit("userCancleOrderFX", mainState);
        localSocket.current.emit("userBalanceById", mainState);
        localSocket.current.emit("subscribe", symbol);
        localSocket.current.emit("userAllOrderFX");
        localSocket.current.emit("notificationLogsFx");
        handleSocketMessages(localSocket, symbol);
      });
    }
    return () => {
      if (localSocket.current && !window.location.pathname.includes("/forex")) {
        console.log("WebSocket connection will be closed on unmount.");
        localSocket.current.disconnect();
      }
    };
  }, []);

  const mergeDataWithOrder = (newData, savedOrder) => {
    const orderMap = new Map();
    savedOrder.forEach((id, index) => {
      orderMap.set(id, index);
    });

    newData.sort((a, b) => {
      const orderA = orderMap.get(a.id) ?? newData.length;
      const orderB = orderMap.get(b.id) ?? newData.length;
      return orderA - orderB;
    });

    return newData;
  };

  const handleSocketMessages = (localSocket, selectedSymbol) => {
    localSocket.current.on("pong", () => {});
    localSocket.current.off("resultForexSymbol");
    localSocket.current.on("resultForexSymbol", (compressedData) => {
      // setCoinPairData(data);
      // const selectedSymbolData = data.filter(
      //   (sym) => sym.symbol === selectedSymbol
      // );
      // if (selectedSymbolData) {
      //   setSelectedBid(selectedSymbolData[0].best_bid);
      //   setSelectedAsk(selectedSymbolData[0].best_ask);
      //   setSelectedIcon(selectedSymbolData[0].icon); //
      // }
      // const savedOrder = JSON.parse(localStorage.getItem("fso")) || [];
      // const mergedData = mergeDataWithOrder(data, savedOrder);

      // setCoinPairData(mergedData);

      try {
        // Decompress the data using pako
        const compressedBuffer = Buffer.from(compressedData, "base64");
        const decompressedData = pako.inflate(compressedBuffer, {
          to: "string",
        });
        const data = JSON.parse(decompressedData);
        // Your existing logic
        const selectedSymbolData = data.filter(
          (sym) => sym.symbol === selectedSymbol
        );
        if (selectedSymbolData.length > 0) {
          setSelectedBid(selectedSymbolData[0].best_bid);
          setSelectedAsk(selectedSymbolData[0].best_ask);
          setSelectedIcon(selectedSymbolData[0].icon); //
        }

        const savedOrder = JSON.parse(localStorage.getItem("fso")) || [];
        const mergedData = mergeDataWithOrder(data, savedOrder);

        setCoinPairData(mergedData);
      } catch (error) {
        console.error("Decompression error:", error);
      }
    });
    localSocket.current.off("userPendingOrderFXResult");
    localSocket.current.on("userPendingOrderFXResult", (data) => {
      setPendingOrders(data);
    });
    localSocket.current.off("userCloseOrderFXResult");
    localSocket.current.on("userCloseOrderFXResult", (data) => {
      setClosedOrders(data);
    });
    localSocket.current.off("userOpenOrderFXResult");
    localSocket.current.on("userOpenOrderFXResult", (data) => {
      setOpenOrders(data);
    });
    localSocket.current.off("userCancleOrderFXResult");
    localSocket.current.on("userCancleOrderFXResult", (data) => {
      setCancleOrders(data);
    });
    localSocket.current.off("notificationLogsResultFx");
    localSocket.current.on("notificationLogsResultFx", (data) => {
      setNotificationData(data);
    });
    localSocket.current.off("userAllOrderFXResult");
    localSocket.current.on("userAllOrderFXResult", (data) => {
      setAllOrdersData(data);
    });
    localSocket.current.off("resultUserBalanceById");
    localSocket.current.on("resultUserBalanceById", (data) => {
      if (data.status) {
        const balanceMetrics = data.data;
        setUserBalance(balanceMetrics.balance);
        setMargin(balanceMetrics.forexMargin);
        setFreeMargin(balanceMetrics.freeMargin);
        setMarginLevel(balanceMetrics.marginLevel);
      } else {
        setUserBalance("0.00");
        setMargin("0.00");
        setFreeMargin("0.00");
        setMarginLevel("0.00");
      }
    });
    localSocket.current.off(`marketDepth:${symbol}`);
    localSocket.current.on(`marketDepth:${selectedSymbol}`, (data) => {
      const marketDepth = JSON.parse(data);
      setMarketDepth(marketDepth);
    });

    localSocket.current.off("refresh");
    localSocket.current.on("refresh", () => {
      const mainState = {
        symbolType: "forex",
      };
      localSocket.current.emit("ping");
      localSocket.current.emit("userCloseOrderFX", mainState);
      localSocket.current.emit("userCancleOrderFX", mainState);
      localSocket.current.emit("userBalanceById", mainState);
      handleSocketMessages(localSocket, selectedSymbol);
    });

    localSocket.current.off("orderRefresh");
    localSocket.current.on("orderRefresh", () => {
      const mainState = {
        symbolType: "forex",
      };
      localSocket.current.emit("userCancleOrderFX");
      localSocket.current.emit("userCloseOrderFX", mainState);
      handleSocketMessages(localSocket, selectedSymbol);
    });
  };

  const onSearch = (event) => {
    setSearchedValue(event.target.value);
  };

  const EmptySearch = () => {
    setSearchedValue("");
  };

  const topBarHtml = () => {
    const balance = new BigNumber(userBalance);
    const profit = new BigNumber(floatingProfits);

    // Equity is the sum of the account balance and the floating profits
    const equityPrice = balance.plus(profit);
    const collectHtml = (
      <ul className="">
        <li>
          <h4 className="ng-binding"> {t("Margin")}</h4>
          <strong className="ng-binding">{margin ? margin : "0.00"}</strong>
          <strong className="transMoney ng-binding ng-scope"></strong>
        </li>
        <li>
          <h4 className="ng-binding">{t("Free Margin")}</h4>
          {isNaN(freeMargin) ? (
            <strong className="changeRate ng-binding ng-scope green">
              {"0.00"}
            </strong>
          ) : (
            <strong className="changeRate ng-binding ng-scope green">
              {freeMargin ? freeMargin : "0.00"}
            </strong>
          )}
        </li>
        <li>
          <h4 className="ng-binding">{t("Margin Level")}</h4>
          {isNaN(marginLevel) ? (
            <strong className="ng-binding">{"0.00"}%</strong>
          ) : (
            <strong className="ng-binding">
              {marginLevel ? marginLevel : "0.00"}%
            </strong>
          )}
        </li>
        <li>
          <h4 className="ng-binding">{t("Equity")}</h4>
          {isNaN(equityPrice) ? (
            <strong className="ng-binding">{"0.00"}</strong>
          ) : (
            <strong className="ng-binding">
              {equityPrice ? formatDecimal(equityPrice, 2) : "0.00"}
            </strong>
          )}
        </li>
        <li>
          <h4 className="ng-binding">{t("Profit")}</h4>
          <strong
            className="ng-binding"
            style={
              floatingProfits < 0 ? { color: "#f23345" } : { color: "#089981" }
            }
          >
            {isNaN(floatingProfits) ? "0.0" : formatDecimal(floatingProfits, 2)}
          </strong>
        </li>
        <li>
          <h4 className="ng-binding">{username}</h4>
          <strong className="ng-binding">
            ${userBalance ? userBalance : "0.00"}
          </strong>
        </li>
      </ul>
    );
    return collectHtml;
  };

  const handlePairClick = (pairName) => {
    setSelectedPair(pairName.symbol);
    setAssetsQuantity(0.01);
    navigate(`/forex/${pairName.symbol}`);
    setCurrentPrice(pairName.best_bid);
    setOrderMethod("market");
    setIsStopLoss(false);
    setIsTakeProfit(false);
    handleSocketMessages(localSocket, pairName.symbol);
    localSocket.current.emit("unsubscribe", symbol);
    localSocket.current.emit("subscribe", pairName.symbol);
  };

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedItem = coinPairData[dragIndex];
      const newData = update(coinPairData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, draggedItem],
        ],
      });
      setCoinPairData(newData);
      const onlyIds = newData.map((item) => item.id);
      localStorage.setItem("fso", JSON.stringify(onlyIds));
    },
    [coinPairData]
  );

  const assetsListHtml = () => {
    const filteredItems = coinPairData
      .filter((item) => item.symbol.includes(searchedValue.toUpperCase()))
      .filter((item) => {
        if (selectedFilter === "All") return true;
        if (selectedFilter === "Forex") return item.symbol_type === "forex";
        if (selectedFilter === "Equity") return item.symbol_type === "equity";
        if (selectedFilter === "Crypto") return item.symbol_type === "crypto";
        return false;
      });
    let moreFilteredItems = filteredItems;

    if (selectedFilter === "All" || selectedFilter === "Forex") {
      let priorityCurrencies = [
        "EURUSD",
        "USDJPY",
        "GBPUSD",
        "USDCHF",
        "AUDUSD",
        "USDCAD",
        "NZDUSD",
        "EURGBP",
        "EURJPY",
        "EURCHF",
        "XAUUSD",
        "XAGUSD",
      ];

      const sortFunc = filteredItems.sort((a, b) => {
        let indexA = priorityCurrencies.indexOf(a.symbol);
        let indexB = priorityCurrencies.indexOf(b.symbol);

        // If both are in the priority list, sort by their index in the priority list
        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
        // If only a is in the priority list, a comes first
        if (indexA !== -1) return -1;
        // If only b is in the priority list, b comes first
        if (indexB !== -1) return 1;
        // If neither are in the priority list, maintain original order
        return 0;
      });

      moreFilteredItems = sortFunc;
    }
    return (
      <tbody>
        {moreFilteredItems.map((pair, index) => {
          const bestAsk = new Decimal(pair.best_ask);
          const bestBid = new Decimal(pair.best_bid);
          const spread = bestAsk.minus(bestBid);
          const showActive = symbol === pair.symbol;

          const htmlPart = (
            <>
              <td className="r-market-pair ng-binding fw600">
                {pair.icon && (
                  <img
                    src={imageUrl + "/static/currencyImage/" + pair.icon}
                    alt="icon"
                    className="currency_icon"
                  />
                )}{" "}
                {/* {console.log("urlllllllllllll",pair)} */}
                {pair.symbol}
              </td>
              <td className="r-market-price ng-binding fw600">
                {spread.toString()}
              </td>
              <td className="r-market-rate text-right">
                <span
                  className="ng-binding green"
                  style={{ color: "rgb(0, 188, 139)" }}
                >
                  {pair.best_ask}
                  <br /> {pair.best_bid}
                </span>
              </td>
            </>
          );

          if (pair.symbol_type === "equity") return null;

          return (
            <>
              <DraggableItem
                key={pair.id}
                index={index}
                id={pair.id}
                text={htmlPart}
                moveItem={moveItem}
                active={showActive}
                onClick={() => handlePairClick(pair)}
              />

              {/* <tr
                key={index}
                className={showActive ? "ng-scope active" : "ng-scope"}
                style={{ cursor: "pointer" }}
                onClick={() => handlePairClick(pair)}
              >
                <td className="r-market-pair ng-binding fw600">
                  {pair.symbol}
                </td>
                <td className="r-market-price ng-binding fw600">
                  {spread.toString()}
                </td>
                <td className="r-market-rate text-right">
                  <span
                    className="ng-binding green"
                    style={{ color: "rgb(0, 188, 139)" }}
                  >
                    {pair.best_ask}
                    <br /> {pair.best_bid}
                  </span>
                </td>
              </tr> */}
              {/* {selectedPair === pair.symbol && (
                <tr className="ng-scope active">
                  <td colspan="3" className="p14">
                    <div className="">
                      <div className="d-flex  ">
                        <button
                          className="buy_btn btn my_color_sell w100"
                          data-dir={2}
                          data-testid="button-sell"
                          onClick={(e) => handleBuyOrder(e, "SELL", pair)}
                        >
                          Sell Market
                        </button>
                        <div className="input_btn d-flex">
                          <button
                            type="button"
                            className=""
                            disabled=""
                            onClick={handleDecrement}
                            style={
                              assetsQuantity > 0.01
                                ? { cursor: "pointer" }
                                : { cursor: "default" }
                            }
                          >
                            <i className="bi bi-dash"></i>
                          </button>
                          <input
                            className="mp_input"
                            type="text"
                            value={assetsQuantity}
                            onChange={(e) => handleAssetsChange(e)}
                          />
                          <button
                            type="button"
                            className=""
                            onClick={handleIncrement}
                            style={
                              assetsQuantity < 10
                                ? { cursor: "pointer" }
                                : { cursor: "default" }
                            }
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                        <button
                          className="btn sell_btn w100"
                          onClick={(e) => handleBuyOrder(e, "BUY", pair)}
                        >
                          Buy Market
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )} */}
            </>
          );
        })}
      </tbody>
    );
  };

  const handleBuyOrder = async (e) => {
    e.preventDefault();
    const orderObj = {
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      orderPrice: sideAcvive === "buy" ? selectedAsk : selectedBid,
      orderQuantity: quantity,
      orderPair: symbol,
      orderType: "market",
      status: "open",
      symbolSide: "forex",
    };
    const res = await createOrderOneClick(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
    } else {
      toast.error(t(res.data.message));
    }
  };

  const handleCloseOrder = async (orderInfo, flotingProfit, closePrice) => {
    console.log(positionClosing, "positionClosing");
    if (positionClosing) return;
    setCurrentClosingOrder(orderInfo);
    const orderObj = {
      orderId: orderInfo.id,
      orderPair: orderInfo.symbol,
      quantity: orderInfo.quantity,
      flotingProfit: flotingProfit ? flotingProfit : "0.0",
      closePrice: closePrice,
    };
    const res = await closeOrderFx(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
      setCurrentClosingOrder(null);
    } else {
      toast.error(t(res.data.message));
      setCurrentClosingOrder(null);
    }
  };
  const handleIncrementPending = () => {
    if (quantity < 1000) {
      setQuantity((prevQuantity) =>
        parseFloat(Math.min(1000, parseFloat(prevQuantity) + 0.01).toFixed(2))
      );
    }
  };

  const handleDecrementPending = () => {
    if (quantity > 0.01) {
      setQuantity((prevPrice) =>
        parseFloat(Math.max(0.01, prevPrice - 0.01).toFixed(2))
      );
    }
  };

  const handleAssetsChangePending = (e) => {
    const value = e.target.value;

    if (value === "") {
      setQuantity("");
      return;
    }
    // Allow user to type floating-point numbers freely
    if (value <= 1000) {
      if (/^(\d+\.?\d{0,2}|\.\d{0,2})$/.test(value)) {
        setQuantity(value);
      }
    }
  };

  const activeBuyOrderHtml = () => {
    const bids = marketDepth
      .filter((item) => item.MDEntryType === "Bid")
      .slice(0, 4); // Display only the first 3 items
    const maxQuantity = Math.max(...bids.map((bid) => bid.MDEntrySize));

    return (
      <tbody>
        {bids.length > 0 ? (
          bids.map((bid, index) => {
            const widthPercent = (bid.MDEntrySize / maxQuantity) * 100;
            const depthQty = parseFloat(bid.MDEntrySize / 100000);
            return (
              <tr key={index} className="tr_prograss_depth">
                <td className="f-left ng-binding green crypt-up">
                  <span
                    className="tr_prograss2"
                    style={{ width: `${widthPercent}%` }}
                  ></span>
                  {bid.MDEntryPx.toFixed(5)}
                </td>
                <td>{depthQty.toLocaleString()}</td>
                <td className="text-right">
                  {(bid.MDEntryPx * depthQty).toFixed(2)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  // Display only the first 3 sell orders (offers)
  const activeSellOrderHtml = () => {
    const offers = marketDepth
      .filter((item) => item.MDEntryType === "Offer")
      .slice(0, 4)
      .reverse(); // Reverse the array      ; // Display only the first 3 items

    const maxQuantity = Math.max(...offers.map((offer) => offer.MDEntrySize));
    return (
      <tbody>
        {offers.length > 0 ? (
          offers.map((offer, index) => {
            const widthPercent = (offer.MDEntrySize / maxQuantity) * 100;
            const depthQty = parseFloat(offer.MDEntrySize / 100000);

            return (
              <tr key={index} className="tr_prograss_depth">
                <td className="f-left ng-binding magenta crypt-down">
                  <span
                    className="tr_prograss"
                    style={{ width: `${widthPercent}%` }}
                  ></span>
                  {offer.MDEntryPx.toFixed(5)}
                </td>
                <td>{depthQty.toLocaleString()}</td>
                <td className="text-right">
                  {(offer.MDEntryPx * depthQty).toFixed(2)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  const sideLastHtml = () => {
    var collectHtml = (
      <ul>
        <li>
          <strong className="ng-binding">{symbol}</strong>
          <strong className="transMoney ng-binding ng-scope"></strong>
        </li>
      </ul>
    );
    return <ul>{collectHtml}</ul>;
  };

  const handleSideChange = (side) => {
    setErrors(defaultError);
    setIsTakeProfit(false);
    // setIsStopLoss(false);
    setSideAcvive(side);
    if (orderMethod === "stop-loss") {
      setIsStopLoss(true);
      setIsTakeProfit(false);
      const currPrice = side === "buy" ? selectedBid : selectedAsk;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (side === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(price, addedPrice);
      setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    }
    setPrice(side === "buy" ? selectedAsk : selectedBid);
  };

  const formatNumberIso = (number) => {
    return number?.toNumber().toFixed(2);
  };

  const handleRightClick = (event, data) => {
    event.preventDefault();
    setCurrentRightClickData(data);
    setMenuPosition({ x: event.clientX - 150, y: event.clientY - 120 });
    setShowMenu(true);
  };
  const handleAction = (action) => {
    if (action === "close") {
      handleCloseOrder(
        currentRightClickData.item,
        currentRightClickData.flotingProfit,
        currentRightClickData.currentPricea
      );
    }

    if (action === "modify") {
      const rowData = currentRightClickData.item;
      setSideAcvive(rowData.order_side.toLowerCase());
      setPrice(rowData.price);
      setStopPrice(rowData.stop_price);
      setTakeProfit(rowData.take_profit);
      setSelectedRowData(rowData);
      setShow(true);
      handleSocketMessages(localSocket, rowData.symbol);
    }

    setShowMenu(false);
  };

  useEffect(() => {
    let totalFloatingProfit = new BigNumber(0);

    openOrders.forEach((item) => {
      const currentPricea =
        item.order_side === "BUY"
          ? new BigNumber(item.buyPrice)
          : new BigNumber(item.sellPrice);
      const orderPrice = new BigNumber(item.price);
      let priceChange;
      if (item.order_side === "BUY") {
        priceChange = currentPricea.minus(orderPrice);
      } else if (item.order_side === "SELL") {
        priceChange = orderPrice.minus(currentPricea);
      }
      let floatingProfit;
      if (item.symbol.startsWith("XAU") || item.symbol.startsWith("XAG")) {
        const quantity = parseFloat(item.quantity);
        floatingProfit = priceChange.multipliedBy(quantity * 100); // Gold uses 100 ounces per lot
      } else if (item.symbol_type === "forex") {
        const quantity = parseFloat(item.quantity) * parseFloat(100000);
        if (item.symbol.endsWith("JPY")) {
          // Special handling for JPY pairs
          floatingProfit = priceChange
            .multipliedBy(quantity)
            .dividedBy(currentPricea);
        } else {
          floatingProfit = priceChange?.multipliedBy(quantity);
        }
      } else if (item.symbol_type === "equity") {
        const quantity = parseFloat(item.quantity);
        floatingProfit = priceChange?.multipliedBy(quantity);
      }
      const commission = new BigNumber(item.commission || 0); // Assuming commission is part of the item object
      const profitAfterCommission = floatingProfit.minus(commission);

      totalFloatingProfit = totalFloatingProfit.plus(profitAfterCommission);
    });
    setFloatingProfits(totalFloatingProfit);
  }, [openOrders]);

  useEffect(() => {
    let totalFloatingProfit = new BigNumber(0);

    closedOrders.forEach((item) => {
      const currentPricea = new BigNumber(item.closed_price);
      const orderPrice = new BigNumber(item.price);
      let priceChange;
      if (item.order_side === "BUY") {
        priceChange = currentPricea.minus(orderPrice);
      } else if (item.order_side === "SELL") {
        priceChange = orderPrice.minus(currentPricea);
      }
      let floatingProfit;
      if (item.symbol.startsWith("XAU") || item.symbol.startsWith("XAG")) {
        const quantity = parseFloat(item.quantity);
        floatingProfit = priceChange.multipliedBy(quantity * 100); // Gold uses 100 ounces per lot
      } else if (item.symbol_type === "forex") {
        const quantity = parseFloat(item.quantity) * parseFloat(100000);
        if (item.symbol.endsWith("JPY")) {
          // Special handling for JPY pairs
          floatingProfit = priceChange
            .multipliedBy(quantity)
            .dividedBy(currentPricea);
        } else {
          floatingProfit = priceChange?.multipliedBy(quantity);
        }
      } else if (item.symbol_type === "equity") {
        const qty =
          item.order_side === "SELL" ? item.buy_quantity : item.sell_quantity;
        const quantity = parseFloat(qty);

        floatingProfit = priceChange?.multipliedBy(quantity);
      }

      const commission = new BigNumber(item.commission || 0); // Assuming commission is part of the item object
      const profitAfterCommission = floatingProfit.minus(commission);

      totalFloatingProfit = totalFloatingProfit.plus(profitAfterCommission);
    });

    setFloatingProfitsClose(totalFloatingProfit);
  }, [closedOrders]);

  const openOrderTable = () => {
    if (openOrders.length > 0) {
      return openOrders.map((item, index) => {
        const currentPricea =
          item.order_side === "BUY"
            ? new BigNumber(item.buyPrice)
            : new BigNumber(item.sellPrice);
        const orderPrice = new BigNumber(item.price);
        let priceChange;
        if (item.order_side === "BUY") {
          priceChange = currentPricea.minus(orderPrice);
        } else if (item.order_side === "SELL") {
          priceChange = orderPrice.minus(currentPricea);
        }
        let floatingProfit;

        if (item.symbol.startsWith("XAU") || item.symbol.startsWith("XAG")) {
          const quantity = parseFloat(item.quantity);
          floatingProfit = priceChange.multipliedBy(quantity * 100); // Gold uses 100 ounces per lot
        } else if (item.symbol_type === "forex") {
          const quantity = parseFloat(item.quantity) * parseFloat(100000);
          if (item.symbol.endsWith("JPY")) {
            // Special handling for JPY pairs
            floatingProfit = priceChange
              .multipliedBy(quantity)
              .dividedBy(currentPricea);
          } else {
            floatingProfit = priceChange?.multipliedBy(quantity);
          }
        } else if (item.symbol_type === "equity") {
          const quantity = parseFloat(item.quantity);
          floatingProfit = priceChange?.multipliedBy(quantity);
        }

        const grossProfitLoss = floatingProfit;

        // Calculate P&L with commission deducted
        const commission = item.commission
          ? new BigNumber(item.commission)
          : new BigNumber(0);
        const netProfitLoss = grossProfitLoss.minus(commission);
        // const swap = calculateSwap(item);

        return (
          <tr
            key={index}
            style={{ cursor: "pointer" }}
            onDoubleClick={() => handleDoubleClick(item)}
            onContextMenu={(e) =>
              handleRightClick(e, {
                item,
                flotingProfit: formatNumberIso(floatingProfit),
                currentPricea: formatNumberIso(currentPricea),
              })
            }
            disabled={positionClosing}
          >
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope"
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>{item.price}</td>
            <td>
              {item.order_side === "BUY" ? item.buyPrice : item.sellPrice}
            </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>
              {item.commission ? "$" + formatDecimal(item.commission, 5) : "-"}
            </td>
            <td>{item.swap ? "$" + item.swap : "-"}</td>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            {/* <td
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              $
              {isNaN(floatingProfit) ? "0.0" : formatDecimal(floatingProfit, 2)}
            </td> */}
            <td
              style={
                netProfitLoss < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              ${isNaN(netProfitLoss) ? "0.0" : formatDecimal(netProfitLoss, 5)}
            </td>
            <td>
              <span
                className="mr-3"
                style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                onClick={() => handleDoubleClick(item)}
              >
                <i class="bi bi-pencil-square"></i>
              </span>
              {positionClosing && currentOrderClosing.id === item.id ? (
                <span
                  className="loading-spin"
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                >
                  <div class="loader" style={{ display: "inline-block" }}></div>
                </span>
              ) : (
                <span
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                  disabled={positionClosing}
                  onClick={() =>
                    handleCloseOrder(
                      item,
                      formatNumberIso(floatingProfit),
                      formatNumberIso(currentPricea)
                    )
                  }
                >
                  <i className="bi bi-x-circle"></i>
                </span>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={10}>
            {t("You have no open orders")}
          </td>
        </tr>
      );
    }
  };

  const allOrdersTable = () => {
    if (allOrdersData.length > 0) {
      return allOrdersData.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>
              {" "}
              {item.order_side === "BUY" ? item.sellPrice : item.buyPrice}{" "}
              <i className="bi bi-arrow-right"></i> {item.price}
            </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>{item.status ? t(item.status) : "-"}</td>
            <td>{item.id ? item.id : "-"}</td>
            {item.status === "pending" && (
              <td>
                <span
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                  onClick={() => handleCancleOrder(item)}
                >
                  <i className="bi bi-x-circle"></i>
                </span>
              </td>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={8}>
            {t("There is no trading data yet")}
          </td>
        </tr>
      );
    }
  };

  const pendingOrderTable = () => {
    if (pendingOrders.length > 0) {
      return pendingOrders.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>
              {" "}
              {item.order_side === "BUY" ? item.sellPrice : item.buyPrice}{" "}
              <i className="bi bi-arrow-right"></i> {item.price}
            </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>
              <span
                style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                onClick={() => handleCancleOrder(item)}
              >
                <i className="bi bi-x-circle"></i>
              </span>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={6}>
            {t("You have no pending orders")}
          </td>
        </tr>
      );
    }
  };

  const closeOrderTable = () => {
    if (closedOrders.length > 0) {
      return closedOrders.map((item, index) => {
        const currentPricea = new BigNumber(item.closed_price);
        const orderPrice = new BigNumber(item.price);
        let priceChange;
        if (item.order_side === "BUY") {
          priceChange = currentPricea.minus(orderPrice);
        } else if (item.order_side === "SELL") {
          priceChange = orderPrice.minus(currentPricea);
        }
        let floatingProfit;
        if (item.symbol.startsWith("XAU") || item.symbol.startsWith("XAG")) {
          const quantity = parseFloat(item.quantity);
          floatingProfit = priceChange.multipliedBy(quantity * 100); // Gold uses 100 ounces per lot
        } else if (item.symbol_type === "forex") {
          const quantity = parseFloat(item.quantity) * parseFloat(100000);
          if (item.symbol.endsWith("JPY")) {
            // Special handling for JPY pairs
            floatingProfit = priceChange
              .multipliedBy(quantity)
              .dividedBy(currentPricea);
          } else {
            floatingProfit = priceChange?.multipliedBy(quantity);
          }
        } else if (item.symbol_type === "equity") {
          const quantity = parseFloat(item.quantity);
          floatingProfit = priceChange?.multipliedBy(quantity);
        }
        const grossProfitLoss = floatingProfit;

        // Calculate P&L with commission deducted
        const commission = item.commission
          ? new BigNumber(item.commission)
          : new BigNumber(0);
        const netProfitLoss = grossProfitLoss.minus(commission);
        return (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>{moment(item.updated_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>
              {item.commission ? "$" + formatDecimal(item.commission, 5) : "-"}
            </td>
            <td>{item.swap ? "$" + item.swap : "-"}</td>
            <td>
              {item.price} <i className="bi bi-arrow-right"></i>{" "}
              {item.closed_price}
            </td>
            {/* <td
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              $
              {isNaN(floatingProfit) ? "0.0" : formatDecimal(floatingProfit, 2)}
            </td> */}
            <td
              style={
                netProfitLoss < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              ${isNaN(netProfitLoss) ? "0.0" : formatDecimal(netProfitLoss, 5)}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={9}>
            {t("You have no close orders")}
          </td>
        </tr>
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessages = { ...errors };

    // Allow empty value to clear the field
    if (value === "") {
      if (name === "price") {
        setPrice(value);
        errorMessages.price = "This field is required";
      }
      if (name === "stopPrice") {
        setStopPrice(value);
        errorMessages.stopPrice = "This field is required";
      }
      if (name === "takeProfit") {
        setTakeProfit(value);
        errorMessages.takeProfit = "This field is required";
      }
      setErrors(errorMessages);
      return;
    }

    const regex = /^\d{1,5}(\.\d{0,5})?$/;
    const isValid = regex.test(value);
    if (!isValid) {
      return;
    }
    if (name === "price") {
      setPrice(value);
      if (!value) {
        errorMessages.price = "This field is required";
      } else if (value <= 0) {
        errorMessages.price = "Price should be greater than zero";
      } else {
        errorMessages.price = "";
      }
    }
    if (name === "stopPrice") {
      setStopPrice(value);
      if (sideAcvive === "buy") {
        if (value >= selectedBid) {
          errorMessages.stopPrice =
            "Set stop loss lower than the current level";
        } else {
          errorMessages.stopPrice = "";
        }
      } else if (sideAcvive === "sell") {
        if (value !== "" && Number(value) <= Number(selectedAsk)) {
          errorMessages.stopPrice =
            "Set stop loss higher than the current level";
        } else {
          errorMessages.stopPrice = "";
        }
      }
    }
    if (name === "takeProfit") {
      setTakeProfit(value);
      if (sideAcvive === "buy") {
        if (value !== "" && Number(value) && value <= selectedAsk) {
          errorMessages.takeProfit =
            "Set take profit higher than the current level";
        } else {
          errorMessages.takeProfit = "";
        }
      } else if (sideAcvive === "sell") {
        if (Number(value) >= Number(selectedBid)) {
          errorMessages.takeProfit =
            "Set take profit lower than the current level";
        } else {
          errorMessages.takeProfit = "";
        }
      }
    }

    setErrors(errorMessages);
  };

  const validateOrder = () => {
    let isValid = true;

    if (isStopLoss) {
      if (sideAcvive === "buy") {
        if (stopPrice >= selectedBid) {
          toast.error(t("Set stop loss lower than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(stopPrice) <= Number(selectedAsk)) {
          toast.error(t("Set stop loss higher than the current level"));
          isValid = false;
        }
      }
    }

    if (isTakeProfit) {
      if (sideAcvive === "buy") {
        if (takeProfit <= selectedAsk) {
          toast.error(t("Set take profit higher than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(takeProfit) >= Number(selectedBid)) {
          toast.error(t("Set take profit lower than the current level"));
          isValid = false;
        }
      }
    }

    return isValid;
  };

  const modifyOrder = async () => {
    // if (!validateOrder()) {
    //   return;
    // }

    const orderObj = {
      orderId: selectedRowData.id,
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      stopPrice: stopPrice ? stopPrice : null,
      takeProfit: takeProfit ? takeProfit : null,
    };
    // console.log(orderObj, "order obje");
    // return;
    const resp = await modifyOrderClick(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
      handleClose();
      setStopPrice("");
      setErrors(defaultError);
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const createPendingOrder = async () => {
    if (!validateOrder()) {
      return;
    }

    let errorMessages = { ...errors };

    if (!price) {
      errorMessages.price = "This field is required";
      setErrors(errorMessages);
      return false;
    }

    if (price <= 0) {
      errorMessages.price = "Price should be greater than zero";
      setErrors(errorMessages);
      return false;
    }

    const orderObj = {
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      orderPrice: price,
      orderQuantity: quantity,
      orderPair: symbol,
      orderType: isStopLoss === false ? "limit" : "stop-loss",
      status: "pending",
      stopPrice: isStopLoss ? stopPrice : "",
      takeProfit: isTakeProfit ? takeProfit : "",
      symbolSide: "forex",
    };
    const resp = await createOrderOneClick(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
      // setPrice("");
      setQuantity(0.01);
      setIsOrderModelOpen(false);
      // setIsStopLoss(false);
      setIsTakeProfit(false);
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const handleCancleOrder = async (order) => {
    const orderObj = {
      orderId: order.id,
    };
    const resp = await cancleOrderFx(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const handleStopCHange = () => {
    setIsStopLoss(!isStopLoss);
    const priceBN = new BigNumber(price);
    const changePrice = priceBN.div(100);
    let addedPrice;

    if (sideAcvive === "buy") {
      addedPrice = priceBN.plus(changePrice);
      const twoPercent = addedPrice.multipliedBy(0.02);
      addedPrice = addedPrice.minus(twoPercent);
    } else {
      addedPrice = priceBN.minus(changePrice);
      const twoPercent = addedPrice.multipliedBy(0.02);
      addedPrice = addedPrice.plus(twoPercent);
    }
    const formattedTakeProfit = formatTakeProfit(price, addedPrice);
    setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
  };

  const handleTakeProfitChange = () => {
    setIsTakeProfit(!isTakeProfit);
    const priceBN = new BigNumber(price);
    const changePrice = priceBN.div(100);
    let addedPrice;

    if (sideAcvive === "buy") {
      addedPrice = priceBN.plus(changePrice);
      const twoPercent = addedPrice.multipliedBy(0.02);
      addedPrice = addedPrice.plus(twoPercent);
    } else {
      addedPrice = priceBN.minus(changePrice);
      const twoPercent = addedPrice.multipliedBy(0.02);
      addedPrice = addedPrice.minus(twoPercent);
    }
    const formattedTakeProfit = formatTakeProfit(price, addedPrice);
    setTakeProfit(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
  };

  const handleOrderTypeCHange = (e) => {
    const { value } = e.target;
    setOrderMethod(value);
    if (value === "stop-loss") {
      setIsStopLoss(true);
      const priceBN = new BigNumber(price);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (sideAcvive === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      }
      console.log(price, changePrice.toNumber(), addedPrice.toNumber());
      const formattedTakeProfit = formatTakeProfit(price, addedPrice);
      setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    }
    // else if (value === "take-profit") {
    //   setIsTakeProfit(true);
    //   setIsStopLoss(false);
    //   const priceBN = new BigNumber(price);
    //   const changePrice = priceBN.div(100);
    //   let addedPrice;

    //   if (sideAcvive === "buy") {
    //     addedPrice = priceBN.plus(changePrice);
    //     const twoPercent = addedPrice.multipliedBy(0.02);
    //     addedPrice = addedPrice.plus(twoPercent);
    //   } else {
    //     addedPrice = priceBN.minus(changePrice);
    //     const twoPercent = addedPrice.multipliedBy(0.02);
    //     addedPrice = addedPrice.minus(twoPercent);
    //   }

    //   setTakeProfit(isNaN(addedPrice.toNumber()) ? 0 : addedPrice.toNumber());
    // }
    else {
      setIsTakeProfit(false);
      setIsStopLoss(false);
    }
    // const calculateStopPrice = parseFloat(currentPrice * 1) / 100;
    // const newPriceBuy =
    //   parseFloat(currentPrice) + parseFloat(calculateStopPrice);

    // setStopPrice(newPriceBuy.toFixed(4));
  };

  var titleData = !currentPrice
    ? "0" +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      symbol +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`
    : currentPrice > 1
    ? currentPrice.toLocaleString() +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      symbol +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`
    : currentPrice +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      symbol +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`;

  const meta = {
    title: titleData.toString(),
    description: `${ProductName} `,
    canonical: `https://${ProductName}.io/`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Bitcoin Exchange, Crypto Buying Selling, Crypto Platform, Cryptocurrencies App ",
      },
    },
  };

  const resetLayout = (type) => {
    if (type === "forex") {
      setLayout(layouts);
      setRemovedItems([]);
      saveToLocalStorage("layouts", layouts);
      saveToLocalStorage("frl", []);
    }
  };

  const changeChartTheme = (theme) => {
    if (theme === "defaultTheme" || theme === "darkTheme") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const removeItem = (itemId) => {
    for (const key in layout) {
      if (layout.hasOwnProperty(key)) {
        layout[key] = layout[key].filter((item) => item.i !== itemId);
      }
    }

    setLayout(layout);
    saveToLocalStorage("layouts", layout);

    setRemovedItems((prevRemovedItems) => [...prevRemovedItems, itemId]);
    setTimeout(() => {
      handleLayoutChange(undefined, layout);
    }, 300);
  };

  const addItem = (newItem) => {
    const updatedLayout = { ...layout };

    let chartData = null;

    for (const key in layouts) {
      if (layouts.hasOwnProperty(key)) {
        const chartItem = layouts[key].find((item) => item.i === newItem);

        if (chartItem) {
          chartData = chartItem;
          break;
        }
      }
    }

    const chartIndex = updatedLayout.lg.findIndex((item) => item.i === newItem);

    if (chartIndex !== -1) {
      updatedLayout.lg[chartIndex] = chartData;
    } else {
      updatedLayout.lg.push(chartData);
    }

    setRemovedItems((prevRemovedItems) =>
      prevRemovedItems.filter((itemId) => itemId !== newItem)
    );

    saveToLocalStorage(
      "frl",
      removedItems.filter((itemId) => itemId !== newItem)
    );

    setLayout(updatedLayout);

    saveToLocalStorage("layouts", updatedLayout);
    setTimeout(() => {
      handleLayoutChange(undefined, updatedLayout);
    }, 300);
  };

  function checkValueExists(value) {
    return removedItems.includes(value);
  }

  const getLayoutSettings = (section, status, page) => {
    if (!status) {
      removeItem(section);
    } else {
      addItem(section);
    }
  };

  const notificationTable = () => {
    if (notificationData.length > 0) {
      return notificationData.map((item, index) => {
        return (
          <tr key={index}>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>{item.title}</td>
            <td>{item.message}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={3}>
            {t("You don't have any notifications!")}
          </td>
        </tr>
      );
    }
  };

  const cancleOrderTable = () => {
    if (cancleOrders.length > 0) {
      return cancleOrders.map((item, index) => {
        if (item.symbol_type === "forex") {
          return (
            <tr key={index}>
              <td>{item.symbol}</td>
              <td>
                <span
                  className="ng-binding ng-scope "
                  style={
                    item.order_side === "BUY"
                      ? { color: "rgb(0, 188, 139)" }
                      : { color: "#eb6a61" }
                  }
                >
                  {t(item.order_side)}
                </span>
              </td>
              <td>{item.quantity}</td>
              <td> {item.price}</td>
              <td>{item.take_profit ? item.take_profit : "-"}</td>
              <td>{item.stop_price ? item.stop_price : "-"}</td>
            </tr>
          );
        }
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={6}>
            {t("You have no pending orders")}
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="forex_fix1">
      <DocumentMeta {...meta}>
        <ExchangeNavBar
          resetLayout={resetLayout}
          changeChartTheme={changeChartTheme}
          getLayoutSettings={getLayoutSettings}
          layoutChanged={removedItems}
          refresh={refresh} // Pass refresh trigger as a prop
        />

        {layout && (
          <ResponsiveGridLayout
            className="layout dashbord_manbox exchange-main-outer fs13"
            layouts={layout}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            rowHeight={30}
            onLayoutChange={handleLayoutChange}
            draggableHandle=".drag-handle"
            draggableCancel=".drag-cancel"
          >
            <div key="topbar">
              <div className="bg2 kline-para kline-para-basic d-flex align-items-center">
                {/* top bar */}
                <div className="box-kline-para-basic-right forex_list ">
                  {topBarHtml()}
                </div>
              </div>
            </div>
            <div key="pairslist" className="dashbord_in dashbord_in_top fs13">
              <div className="forex_left_box padding-right  forex_left_table">
                <div className=" ne_box p-3 br10  bg2 h100">
                  <h6 className="drag-handle text-left">{t("Assets")}</h6>
                  <div className="search-box search-box-new">
                    <input
                      type="text"
                      id="search-int"
                      placeholder={t("Search")}
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                      onChange={onSearch}
                      value={searchedValue}
                      autoComplete="off"
                    />
                    {searchedValue !== "" ? (
                      <i
                        onClick={EmptySearch}
                        className="las la-times-circle"
                      ></i>
                    ) : (
                      <i className="las la-search"></i>
                    )}
                  </div>
                  <table className="table mb-0 currency_table1 ">
                    <thead>
                      <tr>
                        <th
                          className="f-left r-market-pair ng-binding"
                          ng-click="sortByKey('baseAsset')"
                        >
                          {t("Symbol")}
                        </th>
                        <th className="f-left r-market-price ng-binding">
                          {t("Spread")}
                        </th>
                        <th className="r-market-rate ng-binding ng-scope text-right">
                          {t("Ask/Bid")}
                        </th>
                      </tr>
                    </thead>
                  </table>

                  <div className="table-overflow-forex">
                    <DndProvider backend={HTML5Backend}>
                      <table className="table table-hover currency_table">
                        {assetsListHtml()}
                      </table>
                    </DndProvider>
                  </div>

                  {/* <div
                    className={
                      isOrderModelOpen === true
                        ? "pending_order_left open bg2 br10 p-3 h100"
                        : "pending_order_left bg2 br10 p-3 h100 zzz"
                    }
                  >
                    <div className="in_scrool">
                      <div className="d-flex   align-items-center">
                        <img
                          className="currency_icon"
                          src="http://192.168.1.44:8000/static/currencyImage/currency-1659701683724.png"
                          alt=""
                        />
                        {symbol}
                        <i
                          className="bi bi-x-lg ml-auto cursor-pointer"
                          onClick={() => setIsOrderModelOpen(!isOrderModelOpen)}
                        ></i>
                      </div>

                      <div className="d-flex  pending_in_tab mb-3 mt-3 bg1 br10 p-1">
                        <button
                          className={sideAcvive === "sell" ? "active" : ""}
                          onClick={() => handleSideChange("sell")}
                        >
                          {" "}
                          Sell Limit
                          <span
                            style={
                              sideAcvive === "sell"
                                ? { color: "white" }
                                : { color: "#eb6a61" }
                            }
                          >
                            {selectedAsk}
                          </span>
                        </button>
                        <button
                          className={sideAcvive === "buy" ? "active" : ""}
                          onClick={() => handleSideChange("buy")}
                        >
                          {" "}
                          Buy Limit
                          <span
                            style={
                              sideAcvive === "buy"
                                ? { color: "white" }
                                : { color: "rgb(0, 188, 139)" }
                            }
                          >
                            {selectedBid}
                          </span>
                        </button>
                      </div>
                      <h6 className="mt-3 mb-3">
                        {" "}
                        <b>Create pending</b>
                      </h6>

                      <div className="mb-3">
                        <label>Entry price</label>
                        <input
                          type="text"
                          className="form-control"
                          value={price}
                          name="price"
                          onChange={handleChange}
                        />
                        <div className="d-flex mt-1">
                          <small>Distance</small>
                          <small className="ml-auto">42 points</small>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label>Order volume</label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            value={quantity}
                            name="quantity"
                            onChange={handleAssetsChangePending}
                          />
                          <div className="input_btn d-flex input_btn_right">
                            <button
                              type="button"
                              className="cursor-pointer"
                              onClick={handleDecrementPending}
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <button
                              type="button"
                              className="ml-2 cursor-pointer"
                              onClick={handleIncrementPending}
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <small>Monetary equivalent</small>
                          <small className="ml-auto">USD 1000</small>
                        </div>
                      </div>

                      <div className="mb-3 br10 bg3 p-2">
                        <label className="d-flex align-items-center">
                          Stop Loss
                          <small
                            className="ml-auto  cursor-pointer plus_minush collapsed"
                            data-bs-toggle="collapse"
                            href="#collapseTwo"
                            onClick={handleStopCHange}
                          >
                            <i className="bi bi-plus-circle h6 "></i>
                          </small>
                        </label>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          data-bs-parent="#accordion"
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={stopPrice}
                            name="stopPrice"
                            onChange={handleChange}
                          />

                          <div className="d-flex mt-1">
                            <small>Monetary equivalent</small>
                            <small className="ml-auto">USD 1000</small>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 br10 bg3 p-2">
                        <label className="d-flex align-items-center">
                          Take profit
                          <small
                            className="ml-auto  cursor-pointer plus_minush collapsed"
                            data-bs-toggle="collapse"
                            href="#collapseTwo1"
                            onClick={handleTakeProfitChange}
                          >
                            <i className="bi bi-plus-circle h6 "></i>
                          </small>
                        </label>
                        <div
                          id="collapseTwo1"
                          className="collapse"
                          data-bs-parent="#accordion"
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={takeProfit}
                            name="takeProfit"
                            onChange={handleChange}
                          />

                          <div className="d-flex mt-1">
                            <small>Monetary equivalent</small>
                            <small className="ml-auto">USD 1000</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="place_order_fix">
                      <button
                        className="btn w100  mt-2 p-2"
                        onClick={createPendingOrder}
                      >
                        {t("Place order")}
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {!checkValueExists("buy") && (
              <div key="buy">
                <div
                  className={
                    isOrderModelOpen === true
                      ? "pending_order_left open bg2 br10 p-3 h100"
                      : "pending_order_left open bg2 br10 p-3 h100"
                  }
                >
                  <div className="in_scrool ">
                    <div className="d-flex mb-3  align-items-center">
                      <h6 className="mb-0 drag-handle">
                        <b>{t("Create Order")}</b>
                      </h6>
                      <b className="ml-auto">
                        {show ? selectedRowData.symbol : symbol}
                      </b>
                    </div>
                    <select
                      className=" bg1  br10  form-control "
                      value={orderMethod}
                      onChange={handleOrderTypeCHange}
                    >
                      <option value={""}>{t("Select")}</option>
                      <option value={"limit"}>{t("Limit")}</option>
                      <option value={"market"}>{t("Market")}</option>
                      <option value={"stop-loss"}>{t("Stop Loss")}</option>
                      {/* <option value={"take-profit"}>{t("Take Profit")}</option> */}
                    </select>
                    <div className="d-flex  pending_in_tab mb-3 mt-3 bg1 br10 p-1 drag-cancel">
                      <button
                        className={sideAcvive === "sell" ? "active" : ""}
                        onClick={() => handleSideChange("sell")}
                      >
                        {" "}
                        {t("Sell")}
                        <span
                          style={
                            sideAcvive === "sell"
                              ? { color: "white" }
                              : { color: "#eb6a61" }
                          }
                        >
                          {selectedAsk}
                        </span>
                      </button>
                      <button
                        className={sideAcvive === "buy" ? "active" : ""}
                        onClick={() => handleSideChange("buy")}
                      >
                        {" "}
                        {t("Buy")}
                        <span
                          style={
                            sideAcvive === "buy"
                              ? { color: "white" }
                              : { color: "rgb(0, 188, 139)" }
                          }
                        >
                          {selectedBid}
                        </span>
                      </button>
                    </div>
                    {isStopLoss && (
                      <div className="mb-2">
                        <label>{t("Stop Loss")}</label>
                        <input
                          type="text"
                          className="form-control"
                          value={stopPrice}
                          name="stopPrice"
                          onChange={handleChange}
                        />

                        <div className="text-danger">{t(errors.stopPrice)}</div>
                      </div>
                    )}
                    {/* {isTakeProfit && (
                      <div className="mb-2">
                        <label>Take Profit</label>
                        <input
                          type="text"
                          className="form-control"
                          value={takeProfit}
                          name="takeProfit"
                          onChange={handleChange}
                        />
                      </div>
                    )} */}

                    <div className="mb-2">
                      <label>{t("Entry price")}</label>
                      <input
                        type={orderMethod === "limit" ? "number" : "text"}
                        className="form-control"
                        value={orderMethod === "market" ? t("MARKET") : price}
                        name="price"
                        onChange={handleChange}
                        disabled={orderMethod === "market" ? true : false}
                      />
                      <div className="text-danger">{t(errors.price)}</div>
                    </div>

                    {/* {console.log("errorsss",errors)} */}

                    <div className="mb-3">
                      <label>{t("Order volume")}</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          value={quantity}
                          name="quantity"
                          onChange={handleAssetsChangePending}
                        />
                        <div className="input_btn d-flex input_btn_right">
                          <button
                            type="button"
                            className="cursor-pointer"
                            onClick={handleDecrementPending}
                          >
                            <i className="bi bi-dash"></i>
                          </button>
                          <button
                            type="button"
                            className="ml-2 cursor-pointer"
                            onClick={handleIncrementPending}
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div className="d-flex mt-1">
                        <small>{t("Monetary equivalent")}</small>
                        <small className="ml-auto">
                          USD {monetary ? monetary.toString() : ""}
                        </small>
                      </div>
                    </div>
                    {orderMethod !== "market" && (
                      <>
                        {/* <div className="mb-3 br10 bg3 p-2">
                          <label className="d-flex align-items-center mb-0">
                            {t("Stop Loss")}
                            <small
                              className={`ml-auto  cursor-pointer plus_minush pt-1 ${
                                !isStopLoss ? "collapsed" : ""
                              }`}
                              data-bs-toggle="collapse"
                              href={"#collapseTwo"}
                              onClick={handleStopCHange}
                            >
                              <i className="bi bi-plus-circle h6 "></i>
                            </small>
                          </label>
                          <div
                            id="collapseTwo"
                            className={`collapse ${
                              !isStopLoss ? "hide" : "show"
                            }`}
                            data-bs-parent="#accordion"
                          >
                            <input
                              type="text"
                              className="form-control"
                              value={stopPrice}
                              name="stopPrice"
                              onChange={handleChange}
                            />

                            <div className="text-danger">
                              {t(errors.stopPrice)}
                            </div>
                          </div>
                        </div> */}

                        <div className="mb-3 br10 bg3 p-2">
                          <label className="d-flex align-items-center mb-0">
                            {t("Take Profit")}
                            <small
                              className={`ml-auto  cursor-pointer plus_minush pt-1 ${
                                !isTakeProfit ? "collapsed" : ""
                              }`}
                              data-bs-toggle="collapse"
                              href="#collapseTwo1"
                              onClick={handleTakeProfitChange}
                            >
                              <i className="bi bi-plus-circle h6 "></i>
                            </small>
                          </label>
                          <div
                            id="collapseTwo1"
                            className={`collapse ${
                              !isTakeProfit ? "hide" : "show"
                            }`}
                            data-bs-parent="#accordion"
                          >
                            <input
                              type="text"
                              className="form-control"
                              value={takeProfit}
                              name="takeProfit"
                              onChange={handleChange}
                            />
                            <div className="text-danger">
                              {t(errors.takeProfit)}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="place_order_fix">
                    {orderMethod === "market" ? (
                      <button
                        className="btn w100  mt-2 p-2"
                        disabled={isLoading}
                        onClick={handleBuyOrder}
                      >
                        {t("Place order")}
                      </button>
                    ) : (
                      <button
                        className="btn w100  mt-2 p-2"
                        disabled={isLoading}
                        onClick={createPendingOrder}
                      >
                        {t("Place order")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!checkValueExists("chart") && (
              <div key="chart" className="drag-handle">
                <div className="ne_box p-3 br10  bg2 h387">
                  <div className="binance-chart-container">
                    <div
                      className="close-resize drag-cancel"
                      onClick={() => removeItem("chart")}
                    >
                      <i className="bi bi-x-lg"></i>
                    </div>
                    <TradingViewForexWidgit theme={theme} symbol={symbol} />
                  </div>
                </div>
              </div>
            )}
            {!checkValueExists("marketdepth") && (
              <div key="marketdepth">
                <div className=" forex_t_height">
                  <div className="ne_box  p-3 br10  bg2">
                    <h6 className="drag-handle text-left">
                      {t("Market Depth")}
                    </h6>
                    <div
                      className="close-resize drag-cancel"
                      onClick={() => removeItem("marketdepth")}
                    >
                      <i className="bi bi-x-lg"></i>
                    </div>
                    <div className=" bs_border t-height1">
                      <table className="table mb-0 market_depth_table">
                        <thead>
                          <tr>
                            <th>{t("Price")}</th>
                            <th>{t("Qty.")}</th>
                            <th className="text-right">{t("Total")}</th>
                          </tr>
                        </thead>
                        {activeSellOrderHtml()}
                      </table>
                    </div>
                    <h6 className="text-center t2t m-0 bg1">
                      {sideLastHtml()}
                    </h6>
                    <div className="bs_border t-height1 ">
                      <table className="table mb-0 market_depth_table">
                        <thead>
                          <tr>
                            <th>{t("Price")}</th>
                            <th>{t("Qty.")}</th>
                            <th className="text-right">{t("Total")}</th>
                          </tr>
                        </thead>
                        {activeBuyOrderHtml()}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!checkValueExists("orderdetails") && (
              <div key="orderdetails">
                <div className="ne_box pl-3 pr-3 pb-3 pt-2 br10 h100 bg2">
                  <h6 className=" drag-handle text-left">{t("Orders")}</h6>
                  <div
                    className="close-resize drag-cancel"
                    onClick={() => removeItem("orderdetails")}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>

                  <div className="position_tab d-md-flex mb-3">
                    <div className=" d-flex mb-2 mb-md-0">
                      <a
                        className={
                          activeOrderTab === "positions"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() => setActiveOrderTab("positions")}
                      >
                        {t("Positions")}{" "}

                      </a>
                      <a
                        className={
                          activeOrderTab === "all" ||
                          activeOrderTab === "working" ||
                          activeOrderTab === "filled" ||
                          activeOrderTab === "cancelled"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() => setActiveOrderTab("all")}
                      >
                        {t("Orders")}
                      </a>
                      <a
                        className={
                          activeOrderTab === "account_summary"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() => setActiveOrderTab("account_summary")}
                      >
                        {t("Account Summary")}
                      </a>
                      <a
                        className={
                          activeOrderTab === "notification_summary"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() =>
                          setActiveOrderTab("notification_summary")
                        }
                      >
                        {t("Notification Summary")}
                      </a>
                    </div>
                    <div className="total_profit ml-auto">
                      {activeOrderTab === "positions" ? (
                        <h6 className="mb-0 mr-md-2">
                          {t("Total")} {t("P & L")}:{" "}
                          <span
                            style={
                              floatingProfits < 0
                                ? { color: "#f23345" }
                                : { color: "#00bc8b" }
                            }
                          >
                            ${formatDecimal(floatingProfits, 5)}
                          </span>
                        </h6>
                      ) : (
                        <h6 className="mb-0 mr-md-2">
                          {t("Total")} {t("P & L")}:{" "}
                          <span
                            style={
                              floatingProfitsClose < 0
                                ? { color: "#f23345" }
                                : { color: "#00bc8b" }
                            }
                          >
                            ${formatDecimal(floatingProfitsClose, 5)}
                          </span>
                        </h6>
                      )}
                    </div>
                  </div>
                  {(activeOrderTab === "all" ||
                    activeOrderTab === "working" ||
                    activeOrderTab === "filled" ||
                    activeOrderTab === "cancelled") && (
                    <div className="position_tab_in d-flex mb-2 mb-md-0">
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("all")}
                        className={activeOrderTab === "all" ? "active" : ""}
                      >
                        {t("All")}{" "}
                        
                      </a>
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("working")}
                        className={activeOrderTab === "working" ? "active" : ""}
                      >
                        {t("Working")}{" "}
                        
                      </a>
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("filled")}
                        className={activeOrderTab === "filled" ? "active" : ""}
                      >
                        {t("Filled")}{" "}
                        
                      </a>
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("cancelled")}
                        className={
                          activeOrderTab === "cancelled" ? "active" : ""
                        }
                      >
                        {t("Cancelled")}{" "}
                        
                      </a>
                    </div>
                  )}

                  <div className="position-relative h100 pt-3">
                    <div className="forex_table_scrool">
                      {showMenu && (
                        <ContextMenu
                          x={menuPosition.x}
                          y={menuPosition.y}
                          onAction={handleAction}
                          menuRef={menuRef}
                        />
                      )}
                      {activeOrderTab === "positions" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Volume, lots")}</th>
                              <th>{t("Entry point")}</th>
                              <th>{t("Price change")}</th>
                              <th>{t("Take Profit")}</th>
                              <th>{t("Stop Loss")}</th>
                              <th>{t("Commission")}</th>
                              <th>{t("Swap")}</th>
                              <th>{t("Open Time")}</th>
                              {/* <th>{t("Gross Profit")}</th> */}
                              <th>{t("Profit")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{openOrderTable()} </tbody>
                        </table>
                      ) : activeOrderTab === "all" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Volume, lots")}</th>
                              <th>{t("Target level")}</th>
                              <th>{t("Take Profit")}</th>
                              <th>{t("Stop Loss")}</th>
                              <th>{t("Status")}</th>
                              <th>{t("OrderID")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{allOrdersTable()}</tbody>
                        </table>
                      ) : activeOrderTab === "working" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Volume, lots")}</th>
                              <th>{t("Target level")}</th>
                              <th>{t("Take Profit")}</th>
                              <th>{t("Stop Loss")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{pendingOrderTable()}</tbody>
                        </table>
                      ) : activeOrderTab === "filled" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Volume, lots")}</th>
                              <th>{t("Open Time")}</th>
                              <th>{t("Close Time")}</th>
                              <th>{t("Commission")}</th>
                              <th>{t("Swap")}</th>
                              <th>{t("Price change")}</th>
                              {/* <th>{t("Gross Profit")}</th> */}
                              <th>{t("P & L")}</th>
                            </tr>
                          </thead>
                          <tbody>{closeOrderTable()}</tbody>
                        </table>
                      ) : activeOrderTab === "cancelled" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Volume, lots")}</th>
                              <th>{t("Target level")}</th>
                              <th>{t("Take Profit")}</th>
                              <th>{t("Stop Loss")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{cancleOrderTable()}</tbody>
                        </table>
                      ) : activeOrderTab === "account_summary" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Title")}</th>
                              <th>{t("Balance")}</th>
                              <th>{t("Open PL")}</th>
                              <th>{t("Equity")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {" "}
                            <tr>
                              <td>{t("User Balance")}</td>
                              <td>
                                {userBalance
                                  ? Number.isInteger(userBalance)
                                    ? userBalance
                                    : parseFloat(userBalance)
                                  : 0}
                              </td>
                              <td>{formatDecimal(floatingProfits, 5)}</td>
                              <td>
                                {" "}
                                {new BigNumber(userBalance ? userBalance : 0)
                                  .plus(floatingProfits)
                                  .toFixed(5)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Time")}</th>
                              <th>{t("Title")}</th>
                              <th>{t("Message")}</th>
                            </tr>
                          </thead>
                          <tbody>{notificationTable()}</tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ResponsiveGridLayout>
        )}

        {false && (
          <div className="dashbord_manbox exchange-main-outer">
            <div className="container-fluid">
              <div className="dashbord_in dashbord_in_top fs13">
                <div className="bg2 kline-para kline-para-basic d-flex align-items-center">
                  {/* top bar */}
                  <div className="box-kline-para-basic-right forex_list ">
                    {topBarHtml()}
                  </div>
                </div>
                <div className="d-lg-flex">
                  {/* paris with search bar */}
                  <div className="forex_left_box padding-right exchange_left mb-3">
                    <div className="order-history-outer ne_box p-3 br10  bg2">
                      <div className="panel panel-default forex_left_table">
                        <div className="panel-heading tab-box">
                          <div className="panel-body p-0">
                            <div className="searchFilter f-cb">
                              <div className="search-box search-box-new">
                                <input
                                  type="text"
                                  id="search-int"
                                  placeholder={t("Search")}
                                  className="ng-pristine ng-untouched ng-valid ng-empty"
                                  onChange={onSearch}
                                  value={searchedValue}
                                  autoComplete="off"
                                />
                                {searchedValue !== "" ? (
                                  <i
                                    onClick={EmptySearch}
                                    className="las la-times-circle"
                                  ></i>
                                ) : (
                                  <i className="las la-search"></i>
                                )}
                              </div>
                            </div>
                          </div>
                          <ul className="exchange_tab mt-2 mb-2 bg3 pt-1">
                            <li className="active tabQuoteAsset">All</li>
                            <li className="tabQuoteAsset">Forex</li>
                            <li className="tabQuoteAsset">Indices</li>
                            <li className="tabQuoteAsset">Metals</li>
                            <li className="tabQuoteAsset">Energies</li>
                          </ul>
                          <div className="clearfix"></div>
                        </div>

                        <table className="table mb-0 currency_table1 ">
                          <thead>
                            <tr>
                              <th
                                className="f-left r-market-pair ng-binding"
                                ng-click="sortByKey('baseAsset')"
                              >
                                {t("Symbol")}
                              </th>
                              <th className="f-left r-market-price ng-binding">
                                {t("Spread")}
                              </th>
                              <th className="r-market-rate ng-binding ng-scope text-right">
                                {t("Ask/Bid")}{" "}
                              </th>
                            </tr>
                          </thead>
                        </table>

                        <div className="table-overflow-forex">
                          <table className="table table-hover currency_table">
                            {assetsListHtml()}
                          </table>
                        </div>

                        <div
                          className={
                            isOrderModelOpen === true
                              ? "pending_order_left open bg2 br10 p-3 h100"
                              : "pending_order_left bg2 br10 p-3 h100"
                          }
                        >
                          <div className="in_scrool">
                            <div className="d-flex   align-items-center">
                              <img
                                className="currency_icon"
                                src="http://192.168.1.44:8000/static/currencyImage/currency-1659701683724.png"
                                alt=""
                              />
                              {symbol}
                              <i
                                className="bi bi-x-lg ml-auto cursor-pointer"
                                onClick={() =>
                                  setIsOrderModelOpen(!isOrderModelOpen)
                                }
                              ></i>
                            </div>

                            <div className="d-flex  pending_in_tab mb-3 mt-3 bg1 br10 p-1">
                              <button
                                className={
                                  sideAcvive === "sell" ? "active" : ""
                                }
                                onClick={() => handleSideChange("sell")}
                              >
                                {" "}
                                {t("Sell")} {t("Limit")}
                                <span
                                  style={
                                    sideAcvive === "sell"
                                      ? { color: "white" }
                                      : { color: "#eb6a61" }
                                  }
                                >
                                  {selectedAsk}
                                </span>
                              </button>
                              <button
                                className={sideAcvive === "buy" ? "active" : ""}
                                onClick={() => handleSideChange("buy")}
                              >
                                {" "}
                                {t("Buy")} {t("Limit")}
                                <span
                                  style={
                                    sideAcvive === "buy"
                                      ? { color: "white" }
                                      : { color: "rgb(0, 188, 139)" }
                                  }
                                >
                                  {selectedBid}
                                </span>
                              </button>
                            </div>
                            <h6 className="mt-3 mb-3">
                              {" "}
                              <b>{t("Create Order")}</b>
                            </h6>

                            <div className="mb-3">
                              <label>{t("Entry price")}</label>
                              <input
                                type="number"
                                className="form-control"
                                value={price}
                                name="price"
                                onChange={handleChange}
                              />
                              {/* <div className="text-danger">{t(errors.price)}</div> */}

                              {/* <div className="d-flex mt-1">
                                <small>Distance</small>
                                <small className="ml-auto">42 points</small>
                              </div> */}
                            </div>

                            <div className="mb-3">
                              <label>{t("Order volume")}</label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={quantity}
                                  name="quantity"
                                  onChange={handleAssetsChangePending}
                                />
                                <div className="input_btn d-flex input_btn_right">
                                  <button
                                    type="button"
                                    className="cursor-pointer"
                                    onClick={handleDecrementPending}
                                  >
                                    <i className="bi bi-dash"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="ml-2 cursor-pointer"
                                    onClick={handleIncrementPending}
                                  >
                                    <i className="bi bi-plus"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="d-flex mt-1">
                                <small>{t("Monetary equivalent")}</small>
                                <small className="ml-auto">USD 1000</small>
                              </div>
                            </div>

                            <div className="mb-3 br10 bg3 p-2">
                              <label className="d-flex align-items-center">
                                {t("Stop Loss")}
                                <small
                                  className="ml-auto  cursor-pointer plus_minush collapsed"
                                  data-bs-toggle="collapse"
                                  href="#collapseTwo"
                                  onClick={handleStopCHange}
                                >
                                  <i className="bi bi-plus-circle h6 "></i>
                                </small>
                              </label>
                              <div
                                id="collapseTwo"
                                className="collapse"
                                data-bs-parent="#accordion"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  value={stopPrice}
                                  name="stopPrice"
                                  onChange={handleChange}
                                />

                                <div className="d-flex mt-1">
                                  <small>{t("Monetary equivalent")}</small>
                                  <small className="ml-auto">USD 1000</small>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3 br10 bg3 p-2">
                              <label className="d-flex align-items-center">
                                {t("Take Profit")}
                                <small
                                  className="ml-auto  cursor-pointer plus_minush collapsed"
                                  data-bs-toggle="collapse"
                                  href="#collapseTwo1"
                                  onClick={handleStopCHange}
                                >
                                  <i className="bi bi-plus-circle h6 "></i>
                                </small>
                              </label>
                              <div
                                id="collapseTwo1"
                                className="collapse"
                                data-bs-parent="#accordion"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  value={stopPrice}
                                  name="stopPrice"
                                  onChange={handleChange}
                                />

                                <div className="d-flex mt-1">
                                  <small>{t("Monetary equivalent")}</small>
                                  <small className="ml-auto">USD 1000</small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="place_order_fix">
                            <button
                              className="btn w100  mt-2 p-2"
                              disabled={isLoading}
                              onClick={createPendingOrder}
                            >
                              {t("Place order")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row w_100 exchange_right">
                    {/* chart  */}
                    <div className="col-md-8">
                      <div className="ne_box p-3 br10  bg2 ">
                        <div className="binance-chart-container">
                          <TradingViewForexWidgit symbol={symbol} />
                        </div>
                      </div>
                    </div>
                    {/* market depth */}
                    <div className="col-md-4 forex_t_heght">
                      <div className="ne_box  p-3 br10  bg2">
                        <h6 className="">{t("Market Depth")}</h6>
                        <div className=" bs_border t-height">
                          <table className="table mb-0 ">
                            <thead>
                              <tr>
                                <th>{t("Price")}</th>
                                <th>{t("Qty.")}</th>
                                <th className="text-right">{t("Total")}</th>
                              </tr>
                            </thead>
                            {activeSellOrderHtml()}
                          </table>
                        </div>
                        <h6 className="text-center t2t m-0 bg1">
                          {sideLastHtml()}
                        </h6>
                        <div className="bs_border t-height ">
                          <table className="table mb-0 ">
                            <thead>
                              <tr>
                                <th>{t("Price")}</th>
                                <th>{t("Qty.")}</th>
                                <th className="text-right">{t("Total")}</th>
                              </tr>
                            </thead>
                            {activeBuyOrderHtml()}
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* order details */}
                    <div className="col-md-12 mb-2">
                      <div className="ne_box p-3 br10  bg2 mt-2">
                        <div className="d-flex align-items-center mb-2">
                          <h4 className="m-0">{t("Orders")}</h4>
                          <ul className="exchange_tab ml-2">
                            <li
                              className={
                                activeOrderTab === "open"
                                  ? "active tabQuoteAsset"
                                  : "tabQuoteAsset"
                              }
                              onClick={() => setActiveOrderTab("open")}
                            >
                              {t("Open")}
                            </li>
                            <li
                              className={
                                activeOrderTab === "pending"
                                  ? "active tabQuoteAsset"
                                  : "tabQuoteAsset"
                              }
                              onClick={() => setActiveOrderTab("pending")}
                            >
                              {t("Pending")}
                            </li>
                            <li
                              className={
                                activeOrderTab === "close"
                                  ? "active tabQuoteAsset"
                                  : "tabQuoteAsset"
                              }
                              onClick={() => setActiveOrderTab("close")}
                            >
                              {t("Closed")}
                            </li>
                          </ul>
                        </div>
                        <div className="forex_table_scrool">
                          {activeOrderTab === "open" ? (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>{t("Symbol")}</th>
                                  <th>{t("Direction")}</th>
                                  <th>{t("Volume, lots")}</th>
                                  <th>{t("Price change")}</th>
                                  <th>{t("Take Profit")}</th>
                                  <th>{t("Stop Loss ")}</th>
                                  <th>{t("Profit")}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>{openOrderTable()}</tbody>
                            </table>
                          ) : activeOrderTab === "pending" ? (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>{t("Symbol")}</th>
                                  <th>{t("Direction")}</th>
                                  <th>{t("Volume, lots")}</th>
                                  <th>{t("Target level")}</th>
                                  <th>{t("Take Profit")}</th>
                                  <th>{t("Stop Loss ")}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>{pendingOrderTable()}</tbody>
                            </table>
                          ) : (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>{t("Symbol")}</th>
                                  <th>{t("Direction")}</th>
                                  <th>{t("Volume, lots")}</th>
                                  <th>{t("Close time")}</th>
                                  <th>{t("Price change")}</th>
                                  <th>{t("P & L")}</th>
                                </tr>
                              </thead>
                              <tbody>{closeOrderTable()}</tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {show && (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title className="h6">{t("Modify Order")}</Modal.Title>
              <span style={{ cursor: "pointer" }}>
                <i onClick={handleClose} className="bi bi-x-circle"></i>
              </span>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <div className="d-flex  pending_in_tab mb-3 mt-3 bg1 br10 p-1 drag-cancel">
                  <button
                    className={sideAcvive === "sell" ? "active " : ""}
                    disabled
                  >
                    {" "}
                    {t("Sell Limit")}
                    <span
                      style={
                        sideAcvive === "sell"
                          ? { color: "white" }
                          : { color: "#eb6a61" }
                      }
                    >
                      {selectedAsk}
                    </span>
                  </button>
                  <button
                    className={sideAcvive === "buy" ? "active" : ""}
                    disabled
                  >
                    {" "}
                    {t("Buy Limit")}
                    <span
                      style={
                        sideAcvive === "buy"
                          ? { color: "white" }
                          : { color: "rgb(0, 188, 139)" }
                      }
                    >
                      {selectedBid}
                    </span>
                  </button>
                </div>
                {/* <div className="mb-3">
                  <label>Entry price</label>
                  <input
                    type="number"
                    className="form-control"
                    value={price}
                    readOnly
                  />
                </div> */}

                <div className="mb-3 br10 bg3 p-2">
                  <label className="d-flex align-items-center">
                    {t("Stop Loss")}
                  </label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      value={stopPrice}
                      name="stopPrice"
                      onChange={handleChange}
                    />
                    <div className="text-danger">{t(errors.stopPrice)}</div>
                  </div>
                </div>

                <div className="mb-3 br10 bg3 p-2">
                  <label className="d-flex align-items-center">
                    {t("Take Profit")}
                  </label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      value={takeProfit}
                      name="takeProfit"
                      onChange={handleChange}
                    />
                    <div className="text-danger">{t(errors.takeProfit)}</div>
                  </div>
                </div>
              </div>
              <div className="place_order_fix">
                <button className="btn w100  mt-2 p-2" onClick={modifyOrder}>
                  {t("Modify order")}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}
        <div className="copy-right bg2 p-3 text-center br10 mb-3">
          © 2024 EBS Ware. All Rights Reserved{" "}
        </div>
      </DocumentMeta>
    </div>
  );
};

export default ForexExchange;
